const LOG_EVENT_ADDED = 0;
const LOG_EVENT_EDITED = 1;
const LOG_EVENT_DELETED = 2;
const LOG_EVENT_COLORS = {
  [LOG_EVENT_ADDED]: "cyan",
  [LOG_EVENT_EDITED]: "orange",
  [LOG_EVENT_DELETED]: "red"
};
const LOG_EVENT_TEXTS = {
  [LOG_EVENT_ADDED]: "Added",
  [LOG_EVENT_EDITED]: "Edited",
  [LOG_EVENT_DELETED]: "Deleted"
};
export { LOG_EVENT_COLORS as L, LOG_EVENT_TEXTS as a };
