import { FILTERS_GREATER_THAN_OR_EQUAL, STERILIZATION_SPECIFICATIONS_AUDITLOGS } from "@kraftheinz/common";
import { L as LOG_EVENT_COLORS, a as LOG_EVENT_TEXTS } from "./log.event.js";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-audit-log" }, [_c("list-composer", { attrs: { "title": "Sterilisation Audit Log", "has-active": false, "has-filter-inactive": false, "row-key": "transactionId", "search-by": "CreatedBy", "ph-search-by": "Search by Performed by" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("select-filter", { staticClass: "time-filter", attrs: { "data-source": _vm.timeOptions, "default-active-first-option": "", "source": "value", "source-label": "text", "value": { value: "all" } }, on: { "change": _vm.onTimeChange } })];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "profile", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "CreatedBy", attrs: { "allow-html": true, "data-index": "createdBy", "parse": _vm.parseCreatedBy, "title": "Performed by", "width": 231 } }), _c("tag-field", { key: "Mode", attrs: { "align": "center", "data-index": "mode", "title": "Event", "color": _vm.getLogEventColor, "parse": _vm.parseLogEvent, "width": 113 } }), _c("text-field", { key: "DocName", attrs: { "allow-html": true, "data-index": "docName", "parse": _vm.parseWhatChanged, "title": "What changed", "width": 271 } }), _c("text-field", { key: "ChangeDetails", attrs: { "allow-html": true, "data-index": "changeDetails", "title": "Changes details" } })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListAuditLog_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListAuditLog",
  inject: ["crud"],
  data() {
    const timeOptions = [
      { value: "all", text: "All time" },
      { value: 0, text: "Today" },
      { value: 1, text: "Yesterday" },
      { value: 7, text: "Last 7 days" },
      { value: 14, text: "Last 14 days" },
      { value: 30, text: "Last 30 days" }
    ];
    return {
      FILTERS_GREATER_THAN_OR_EQUAL,
      timeOptions
    };
  },
  methods: {
    getLogEventColor(value) {
      return LOG_EVENT_COLORS[value];
    },
    parseCreatedBy(value, record) {
      return `
        <div class="h6">${value}</div>
        <div class="b8">${this.$moment(record.createdAt).format("DD/MM/YY hh:mm A")}</div>
      `;
    },
    parseLogEvent(value) {
      return LOG_EVENT_TEXTS[value];
    },
    parseWhatChanged(value, record) {
      return `<span class="h7">${value} - ${record.docKey}</span>`;
    },
    onTimeChange({ value }) {
      if (value == "all") {
        this.crud.deleteFilter("CreatedAt");
        this.crud.fetchList();
        return;
      }
      const timeFrame = this.$moment().subtract(value, "days").toISOString();
      this.crud.setFilter("CreatedAt", {
        operator: FILTERS_GREATER_THAN_OR_EQUAL,
        value: timeFrame
      });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "2ffcf5f4", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListAuditLog = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.audit-logs", "api-url": _vm.apiUrl, "edit-route": "/sterilization-specifications/audit-logs/:id", "resource-id-name": "transactionId" } }, [_c("list-audit-log")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ListAuditLog
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_AUDITLOGS,
      apiUrl: "#{VUE_APP_API_URL}#",
      menuItems: [
        {
          title: "Audit Log",
          path: ""
        },
        {
          title: "Sterilisation Audit Log",
          path: "/sterilization-specifications/audit-logs"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
